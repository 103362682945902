import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import "./style/userscan.scss"
import logo from "./images/logo.svg"
import MissedCalled from "./images/missed-out.svg"

const CallErrorHandler = (props: any) => {
	const navigate = useNavigate();
	const location = useLocation();
	
	// ACCESS THE MESSAGE FROM THE STATE
	// @ts-ignore
	const message = location.state?.message;
	
	const handleGoHome = () =>
	{
		navigate('/dashboard'); // Redirect to the home route
	};
	
	return (
		<div className="main-wrap" style={{alignItems:"flex-start"}}>
			<div className="banner-experience py-2" style={{height:'60px'}}>
				<img src={logo} alt="" />
			</div>
			<div className="container" style={{marginTop:'60px'}}>
				<div className="row">
					<div className="col-xxl-12 m-auto text-center thankscall-wrap">
						<img src={MissedCalled} alt="" width={150} className="image_call" />
						<div className="text-center thanks_text">
							<h4 className="mt-5" style={{color:"white"}}>
								{message || 'Something went wrong. Please try again later.'}
							</h4>
							
						</div>
					</div>
				</div>
				<div className="actionContainer">
					<button className="btn btn-primary" onClick={handleGoHome}>
						Go to Home
					</button>
				</div>
			</div>
		</div>
	);
};

export default CallErrorHandler;
