// SET PEER CONNECTION WITH REMOTE USER.
import store from "../../../_metronic/redux/store"
import {globals} from "../../utils/globals"
import {socket} from "../../services/helper.service"
import {createLog} from "../../services/logger.service"
let pendingCandidates: any = [];

export const constraints =
	{
		video: {
			width: {
				min: "300",
				max: "640",
			},
			height: {
				min: "200",
				max: "480",
			},
			echoCancellation: {exact: true},
			noiseSuppression: {exact: true},
			facingMode: "user",
		},
		audio: {
			echoCancellation: {exact: true},
			noiseSuppression: {exact: true},
		},
	}

export const setPeerConnection =
(
	remoteUserSocketId: string,
	remoteUsername: string,
	me: string, location:any
) =>
{
	// TRY TO CREATE PEER CONNECTION.
	try
	{
		createLog ("webrtc_debug", "called set peer connection for ", remoteUserSocketId);
		pendingCandidates = [];
		let peerConnection: RTCPeerConnection; // VARIABLE TO HOLD NEW PEER CONNECTION.
		
		// ICE SERVER CONFIG ADDRESS
		let iceServerConfig = store.getState().LoaderReducer.turnDetails;
		// let iceServerConfig =
		// {
		// 	"iceServers":
		// 	[
		// 		{"urls":["stun:stun.l.google.com:19302","stun:69.167.165.147:3478" ]},
		// 		{
		// 			"username": "hGUdMnPVbWB4mO6mMq",
		// 			"credential": "0ef709e0-71f7-11eb-80a1-0242ac140021",
		// 			"urls":["turns:letskinect.com:5349"]
		// 		}
		// 	],
		// 	"iceTransportPolicy":"all",
		// 	"bundlePolicy": "balanced",
		// 	"rtcpMuxPolicy": "negotiate"
		// }
		// let iceServerConfig =
		// 	{
		// 		"iceServers": [
		// 			{
		// 				"url": "stun:global.stun.twilio.com:3478",
		// 				"urls": "stun:global.stun.twilio.com:3478"
		// 			},
		// 			{
		// 				"url": "turn:global.turn.twilio.com:3478?transport=udp",
		// 				"username": "10f5ca0cd1a9e39188c0920b9b351be193c77da0d56a87cab6fc0bb41dfb647f",
		// 				"urls": "turn:global.turn.twilio.com:3478?transport=udp",
		// 				"credential": "f18lPaCwgsVwrhjGilDo5SXVR2HQoFxNLZSlt4iYfGs="
		// 			},
		// 			{
		// 				"url": "turn:global.turn.twilio.com:3478?transport=tcp",
		// 				"username": "10f5ca0cd1a9e39188c0920b9b351be193c77da0d56a87cab6fc0bb41dfb647f",
		// 				"urls": "turn:global.turn.twilio.com:3478?transport=tcp",
		// 				"credential": "f18lPaCwgsVwrhjGilDo5SXVR2HQoFxNLZSlt4iYfGs="
		// 			},
		// 			{
		// 				"url": "turn:global.turn.twilio.com:443?transport=tcp",
		// 				"username": "10f5ca0cd1a9e39188c0920b9b351be193c77da0d56a87cab6fc0bb41dfb647f",
		// 				"urls": "turn:global.turn.twilio.com:443?transport=tcp",
		// 				"credential": "f18lPaCwgsVwrhjGilDo5SXVR2HQoFxNLZSlt4iYfGs="
		// 			}
		// 		],
		// 		"bundlePolicy": "max-bundle",
		// 		"rtcpMuxPolicy": "negotiate"
		// 	}
		console.log (`1.1 - location iceServerConfig.iceServerConfig`, iceServerConfig);
		
		// CONNECTION START TO RTC PEER
		peerConnection = new RTCPeerConnection (iceServerConfig as any);
		// peerConnection.onnegotiationneeded = () => handleNegotiation (remoteUserSocketId, remoteUsername, me, location);
		peerConnection.onicecandidate = (event: any) => handleICECandidateEvent (event, remoteUserSocketId);
		
		// STORE THE PEER CONNECTION TO GLOBAL VARIABLE.
		globals.peerConnection = peerConnection;
		return peerConnection;
	}
	catch (e)
	{
		console.error ("error in starting peer connection ", e);
		return null;
	}
}

export const handleICECandidateEvent = (event:any, remoteUserSocketId:string) =>
{
	if (event.candidate)
	{
		let obj =
		{
			candidate: event.candidate,
			callTo: remoteUserSocketId,
		};
		console.log("onicecandidate remoteUserSocketId", remoteUserSocketId , "payload Obj ",obj);
		socket.emit("offer-candidate", obj);
		console.log("webrtc", "step6-sending-offer-candidate");
	}
}

export const isThereVideoInPeer = (peerConnection: RTCPeerConnection) =>
{
	return peerConnection?.getSenders()?.some (sender =>
	{
		const track = sender.track;
		return track && track.kind === "video";
	});
}

export const isThereAudioInPeer = (peerConnection: RTCPeerConnection) =>
{
	return peerConnection?.getSenders()?.some (sender =>
	{
		const track = sender.track;
		return track && track.kind === "audio";
	});
}

// HANDLE WEBRTC NEGOTIATION EVENT. HERE TWO PEER TALK TO EACH OTHER TO ESTABLISH A CONNECTION.
// THIS FUNCTION WILL BE CALLED FOR THE USER WHO JOINS ON SECOND NUMBER.
// FORMER handleNegotiation, CHANGING THE BEHAVIOUR OF THIS FUNCTION BECAUSE ONNEGOTIATIONNEEDED WAS
// TRIGGERING MULTIPLE TIMES.
export const sendOfferToRemoteUser = async (remoteUserSocketId:string, remoteUsername: string, me: string, location:any, iceRestart:boolean=false) =>
{
	createLog ("webrtc_debug", "handling negotiation ", remoteUserSocketId, "next: send offer.");
	try
	{
		let peerConnection:RTCPeerConnection = getPeerConnection();
		const hasVideoTrack = isThereVideoInPeer (peerConnection);
		
		// SEND OFFER OBJECT TO REMOTE USER.
		let offerParams =
		{
			offerToReceiveAudio: true,
			offerToReceiveVideo: true, // 1
			voiceActivityDetection: true,
			iceRestart: iceRestart,
		}
		
		// CREATE OFFER.
		const offer = await peerConnection.createOffer (offerParams);
		await peerConnection.setLocalDescription (offer);
		
		let payload  =
		{
			userToCall: remoteUserSocketId,
			signalData: offer,
			from: me || socket.id,
			name: remoteUsername,
			data: location.state,
			mediaStatus:
			{
				type: (!hasVideoTrack) ? "mic" : "both",
				'myMediaStatus': (!hasVideoTrack) ? true : [true, true]
			},
			callType: (hasVideoTrack) ? "video" : "audio",
		}
		socket.emit ("offer", payload)
		createLog ("webrtc_debug", "offer sent. ", "next: handle answer");
	}
	catch (error)
	{
		console.error("error code 106:", error);
	}
}

// GET ALL REMOTE PEER CONNECTION ACCORDING TO THE TYPE.
export const closeUserPeerConnections = () =>
{
	// IF THE CONNECTIONS OF THE GIVEN USER EXIST THEN REMOVE THEM.
	if (globals.peerConnection && typeof globals.peerConnection !== null)
	{
		// REMOVE MEDIA TRACKS.
		globals.peerConnection?.getSenders()?.forEach ((sender:any) =>
		{
			sender?.track?.stop();
			globals.peerConnection.removeTrack (sender);
		});
		globals.peerConnection?.close();
		globals.peerConnection = null;
	}
	pendingCandidates = [];
}

// RETURN THE PEER CONNECTION OF THE GIVEN STREAM ID AND CALL TYPE.
export const getPeerConnection = () =>
{
	// CHECK IF PEER CONNECTION EXIST.
	if (globals.peerConnection !== null)
	{
		return globals.peerConnection;
	}
	return null;
}


// FUNCTION TO HANDLE TO BOTH FUNCTIONALITY INCOMING-CALL AND OUTGOING-CALL.
// TO ADD INCOMING ICE CANDIDATE.
export const onIceCandidateOffer = (incoming: {candidate: RTCIceCandidateInit | undefined; }) =>
{
	// LOG DETAILS OF THE INCOMING OFFER CANDIDATE
	console.log ("webrtc", "step4-received-offer--candidate-event", incoming);
	let peerConnection = getPeerConnection();
	
	// IF PEER CONNECTION IS AVAILABLE, ADD THE CANDIDATE
	// CHECKS THE PEER CONNECTION REMOTE DESCRIPTION IS SET OR NOT. IF NOT, THEN ADD THE CANDIDATE TO PENDING CANDIDATES.
	if (peerConnection !== null && peerConnection.remoteDescription )
	{
		try
		{
			console.log("Adding ICE candidate");
			const candidate = new RTCIceCandidate (incoming.candidate);
			peerConnection.addIceCandidate(candidate).catch((e: any) =>
			{
				console.error("Error adding ICE candidate: ", e);
				// Handle ICE candidate error, e.g., retry or fail call
			});
			
		} catch (error) {
			console.error("Error in onIceCandidateOffer:", error);
		}
	}
	else
	{
		console.log ("Queuing candidate as remote description is not set.");
		
		// MAKE SURE pendingCandidates IS WELL DEFINED.
		if (typeof pendingCandidates === "undefined")
		{
			pendingCandidates = [];
		}
		
		// STORE THE CANDIDATE FOR LATER WHEN THE REMOTE DESCRIPTION IS SET
		pendingCandidates.push (incoming.candidate);
	}
}

export const processPendingCandidates = (peerConnection: RTCPeerConnection) =>
{
	if (pendingCandidates && typeof pendingCandidates !== "undefined" && pendingCandidates.length > 0)
	{
		console.log("Processing pending ICE candidates");
		pendingCandidates.forEach ((candidate: any) =>
		{
			peerConnection.addIceCandidate (new RTCIceCandidate (candidate)).catch ((e) =>
			{
				console.error("Error adding pending ICE candidate: ", e);
			});
		});
		pendingCandidates = []; // CLEAR THE QUEUE
	}
};

export const removeStoppedTrackFromPeerConnection = (track: MediaStreamTrack) =>
{
	let peerConnection: RTCPeerConnection = getPeerConnection();
	
	// IF TRACK HAS ENDED THEN REMOVE IT.
	if (track.readyState === "ended" && peerConnection)
	{
		const sender = peerConnection?.getSenders()?.find(s => s.track === track);
		if (sender)
		{
			peerConnection.removeTrack (sender); // REMOVE THE TRACK FROM THE CONNECTION
			createLog ("webrtc_debug", "Track removed from PeerConnection:", track.kind);
		}
		else
		{
			console.warn ("No sender found for track:", track);
		}
	}
};
