/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext} from "react"
import VideoContext from "./VideoContext"
import "../user-scan-qrcode/style/userscan.scss"
import PhoneHangup from "../user-scan-qrcode/images/phone-hangup.svg"
import Micoff from "../../../_metronic/helpers/svg-helper/Micoff"
import Micon from "../../../_metronic/helpers/svg-helper/Micon"
import Videoon from "../../../_metronic/helpers/svg-helper/Videoon"
import Videooff from "../../../_metronic/helpers/svg-helper/Videooff"
import CameraFlip from "../../../_metronic/helpers/svg-helper/CameraFlip"
import CommonPage from "./CommonPage"
import {Spinner} from "react-bootstrap";
import VideoCall from "../../../_metronic/helpers/svg-helper/videocall"
import SpeakerOn from "../../../_metronic/helpers/svg-helper/speakeron"
import SpeakerOff from "../../../_metronic/helpers/svg-helper/speakeroff"
import VideoCallView from "./videocall.view";

const Video = () =>
{
  const {
    switchCam,
    isSafariBrowser,
    staffTimeOut,
    callRejected,
    remoteUserSocketId,
    sec,
    call,
    callAccepted,
    myVideo,
    userVideo,
    stream,
    name,
    callEnded,
    userName,
    isMyVideoOn,
    userVdoStatus,
    updateVideo,
    myMicStatus,
    userMicStatus,
    updateMic,
    staffUnavailable,
    leaveCall,
	message,
	callStatus,
	switchLoudSpeaker,
	phoneSpeaker,
	videoRequestModel,
	isVideoCallRequesting,
	cancelVideoCallRequest,
	acceptVideoCallRequest,
	rejectVideoCallRequest,
	receivedVideoRequest,
	callType
  } = useContext(VideoContext)

  return (
    <>
      <video playsInline muted ref={myVideo} autoPlay style={{display: "none"}} />

      {staffUnavailable || callRejected || staffTimeOut ? (
        <CommonPage
          displayDiv={
            <>
              <div className="text-center thanks_text">
                    <h2 className="mt-5 pt-5">
	                    {
							message
							?
								message
							:   "No staff available, Please call after some time."
						}
					</h2>
              </div>
            </>
          }
        />
      ) : (
        <div className="main-wrap">
          <div className="container-fluid p-0">
            <div className="row m-auto">
              <div className="col-md-12 p-0">
                <div className="call-main video-block" style={{ display: `${callAccepted || remoteUserSocketId ? "block" : "none"}`}}>
					<VideoCallView />
                </div>

                {/*loading spinner */}
                <div
                  className="text-center thanks_text"
                  style={{
                    display: `${callAccepted || remoteUserSocketId ? "none" : "block"}`,
                  }}
                >
                  <span style={{marginBottom: "1rem", fontSize: "2rem"}}>
                    {/*Connecting to the available staff*/}
	                {callStatus? callStatus : "We are connecting your call. Please wait . . ."}
                  </span>
                  <br />
                  <br />
                  <br />
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{width: "2.5rem", height: "2.5rem"}}
                    className="ms-1"
                  />
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{width: "2.5rem", height: "2.5rem"}}
                    className="ms-1"
                  />
                  <Spinner
                    animation="grow"
                    size="sm"
                    style={{width: "2.5rem", height: "2.5rem"}}
                    className="ms-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Video
