/* eslint-disable jsx-a11y/anchor-is-valid */
import "./style/userscan.scss"
import moment from "moment"
import * as Yup from "yup"
import jwt_decode from "jwt-decode"
import {useNavigate, useParams} from "react-router-dom"
import call from "./images/icon-call.svg"
import logo from "./images/logo.svg"
import InvalidImage from "./images/invalid-qr.svg"
import QrUserDetailsInput from "../../../_metronic/Shared-Component-formik/input/QrUserDetailsInput"
import {Formik, Form} from "formik"
import {ValSchemaList} from "../../../_metronic/Shared-Component-formik/schema/ValSchemaList"
import {isMobile} from "react-device-detect"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import CommonPage from "../calling/CommonPage"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {TurnServerAction} from "../../../_metronic/redux/actions/LoaderAction"
import {toast} from "react-hot-toast"
import {userUtcOffset} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import {httpRequest} from "../../services/network.service"

const UserScanQrCode = () =>
{
	const navigate = useNavigate ();
	const dispatch = useDispatch ();
	
	const {token, detailsToken}: any = useParams ()
	const token_decoded: any = token !== "qrcode_expired" && jwt_decode (token)
	const detail_token_decoded: any = detailsToken !== "expired" && jwt_decode (detailsToken)
	const [isLoaded, setIsLoaded] = useState (false);
	const [webrtcConfig, setWebrtcConfig] = useState<any>()
	const [browserAgent, setBrowserAgent] = useState("");
	const [isEnabledDirectCall, setIsEnabledDirectCall] = useState<any>("");
	const [havePermissions, setHavePermissions] = useState<any>(false)
	const [toastMsz, setToastMsz] = useState ("");
	const [initialValues, setInitialValues] = useState({});
	const [valSch, setValSch] = useState({});
	const [validationSchema, setValidationSchema] = useState(Yup.object({}));
	
	useEffect(() =>
	{
		const browser = detectBrowserAgent();
		setBrowserAgent(browser);
		
		if (token_decoded)
		{
			let enabledDirectCall = token_decoded.enabledDirectCall | 0;
			setIsEnabledDirectCall (enabledDirectCall); // SET ENABLED DIRECT CALL.
			
			// ASSIGN INITIAL VALUES AND VALIDATION SCHEMA
			const initVals: any = {};
			const valSchema: any = {};
			
			// CHECK TOKEN DECODED FIELDS AND IS NOT ENABLED DIRECT CALL.
			if (typeof token_decoded?.fields == "object" && enabledDirectCall == 0)
			{
				token_decoded?.fields?.forEach ((e: any) =>
				{
					const fieldKey = Object.keys (JSON.parse (e))[0];
					const fieldType = Object.values (JSON.parse (e))[0];
					
					// ASSIGN INITIAL VALUE
					initVals[fieldKey] = "";
					
					// ASSIGN VALIDATION SCHEMA
					valSchema[fieldKey] = ValSchemaList (
						fieldType === "email" ? 1: fieldType === "contact_no" ? 4: 0,
						fieldType,
						"",
						"",
						fieldKey
					);
				});
				
				setInitialValues (initVals);
				setValSch (valSchema);
				setValidationSchema (Yup.object (valSchema));
			}
		}
		
		console.log (`call_debug: file: UserScanQrCode.js:32 ===> browserAgent`, browser);
		console.log (`call_debug: file: token_decoded.js:32 ===> token_decoded`, token_decoded);
		console.log (`call_debug: file: detail_token_decoded.js:32 ===> detail_token_decoded`, detail_token_decoded);
		
		// GET TURN SERVER CONFIGURATION.
		getTurnDetails().then();
	}, []);
	
	
	// DETECT BROWSER AGENT OF USER.
	const detectBrowserAgent = () =>
	{
		const userAgent = navigator.userAgent.toLowerCase();
		if (userAgent.includes("chrome")) return "Chrome";
		if (userAgent.includes("firefox")) return "Firefox";
		if (userAgent.includes("safari") && !userAgent.includes("chrome")) return "Safari";
		if (userAgent.includes("edge")) return "Edge";
		
		//@ts-ignore
		if (userAgent.includes("MSIE") || !!document?.documentMode == true) return "IE";
		if (userAgent.includes("opera") || userAgent.includes("opr")) return "Opera";
		return "Unknown";
	};

	// GET THE TURN SERVER CONFIGURATION FROM SERVER.
	const getTurnDetails = async () =>
	{
		try
		{
			const response = await httpRequest (`serverDetails/iceserver`, `GET`);
			console.log ("call_debug: serverDetails iceserver apiRes", response);
			
			//  RESPONSE STATUS SUCCESS THEN SET TURN SERVER CONFIGURATION.
			if (response?.status === "success" && response?.webIceConfig)
			{
				const configuration = response.webIceConfig;
				console.log ("call_debug: set ice server config", configuration.iceServers)
				dispatch(TurnServerAction(configuration));
				setWebrtcConfig(configuration);
			}
		} catch (error)
		{
			console.error ("Error fetching TURN server details:", error);
		} finally
		{
			setIsLoaded (true);
		}
		
		// if (JSON.parse (apiRes.request.response).status === "error")
		// {
		// 	console.error ("use default stun server", apiRes.request);
		// 	dispatch (TurnServerAction ({iceServers: [{urls: "stun:stun.l.google.com:19302"}]}))
		// }
	}
	
	useEffect (() =>
	{
		// CHECK THE BROWSER AGENT AND ENABLE DIRECT CALL, THEN NAVIGATE TO CALLING PAGE DIRECTLY.
		// @ts-ignore
		if (browserAgent && isEnabledDirectCall == 1)
		{
				checkMedia ().then();
		}
	}, [browserAgent, isEnabledDirectCall]);
	
	const renderToast = () =>
	{
		toast.dismiss ()
		toast (
			(t) => (
				<div className="d-flex justify-content-between">
					{toastMsz}
					
					<a className="ms-2" onClick={() => toast.dismiss (t.id)}>
            <span className="svg-icon svg-icon-3">
              <svg
				  xmlns="http://www.w3.org/2000/svg"
				  width={55}
				  height={55}
				  fill="currentColor"
				  className="bi bi-x-circle"
				  viewBox="0 0 16 16"
			  >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path
					d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </span>
					</a>
				</div>
			),
			{
				duration: 10000,
				position: "top-center",
				style: {
					border: "1px solid orange",
					color: "orange",
				},
			}
		)
		// return <ConfirmModel type="info" delMsz={delMsz} show={delId} onHide={setDelId} />
	}
	
	const checkPermission = async () =>
	{
		let arr = [{name: "camera"}, {name: "microphone"}, {name: "notifications"}]
		let values : any = []
		for (let index = 0; index < arr.length; index++)
		{
			const element: any = arr[index]
			await navigator.permissions.query (element).then ((res: any) =>
			{
				if (values.length === 0)
				{
					let value = {
						name: res.name,
						permission: res.state,
					}
					values.push (value)
				} else
				{
					let filter = values.findIndex ((value1: any) =>
					{
						return value1.name == res.name
					})
					if (filter === -1)
					{
						let value = {
							name: res.name,
							permission: res.state,
						}
						values.push (value)
					} else
					{
						values[filter].permission = res.permission
					}
				}
			})
		}
		return values
	}
	
	const checkMedia = async (abc = {}) =>
	{
		console.log (`call_debug: checking browser`, browserAgent);
		
		// CHECK BROWSER AGENT IS BROWSER CHROME OR OPERA, THEN CHECK PERMISSION. ELSE START CALLING.
		if (browserAgent == "Chrome" || browserAgent == "Opera")
		{
			setToastMsz(""); // CLEAR TOAST.
			
			console.log (`call_debug: checking permission`);
			
			// CHECK PERMISSION FOR CAMERA AND MICROPHONE.
			let response = await checkPermission ()
			if (response[0]?.permission === "granted" && response[1]?.permission === "granted")
			{
				console.log (`call_debug: permission granted`);
				setHavePermissions ("granted")
				// START CALLING
				await startCall (abc);
			}
			if (response[0]?.permission == "denied" || response[1]?.permission == "denied")
			{
				setHavePermissions ("denied")
				console.log (`call_debug: permission denied`)
				setToastMsz ("We need access to your camera and microphone." +
					"Click the LOCK icon in your browser's address bar > " +
					"click on permission > reset permissions > refresh page")
			}
			if (response[0]?.permission == "prompt" || response[1]?.permission == "prompt")
			{
				console.log (`call_debug: prompt`)
				setHavePermissions ("prompt")
				setToastMsz ("Please allow camera and microphone permission whenever asked.")
				navigator.mediaDevices.getUserMedia ({
					video: {
						width: {
							min: "300",
							max: "640",
						},
						height: {
							min: "200",
							max: "480",
						},
					},
					audio: true,
				}).then (async (currentStream) =>
				{
					setToastMsz ("")
					toast.dismiss ();
					setHavePermissions ("granted");
					
					// START CALLING
					await startCall (abc);
				})
			}
		} else
		{
			console.log (`call_debug: Browser chrome or opera`)
			
			// START CALLING
			await startCall (abc);
		}
	}
	
	const startCall = async (abc = {}) =>
	{
		let qrCodeData =
		{
			forminput: abc,
			'qrOrgName': detail_token_decoded.qrOrgName,
			'qrcodeName': detail_token_decoded.qrcodeName,
			'defaultstaff': detail_token_decoded?.defaultstaff,
			'lastcalled': detail_token_decoded?.lastcalled,
			'door_id': token_decoded.door_id,
			'enabledDirectCall': token_decoded.enabledDirectCall,
			'lastattendedby': detail_token_decoded?.lastattendedby,
		}
		let state = {
			staffToCall: detail_token_decoded?.staffsArr,
			qrCodeId: detail_token_decoded?.qrcodeId,
			orgId: token_decoded?.organizationId,
			door_id: token_decoded.door_id,
			enabledDirectCall: token_decoded.enabledDirectCall,
			//default_staff_id: detail_token_decoded?.defaultstaff?.user_id,
			userInfo: qrCodeData,
			webrtcConfig: webrtcConfig,
			callingStatus: "O"
		};
		navigate ("/calling", {state: state});
	}
	
	// HANDLE CALL FUNCTION FOR USING THE CHECKING PERMISSIONS AND THEN START CALLING.
	const handleCall = async (abc= {}, isDirectCall: boolean= false) =>
	{
		console.log (`call_debug: checking browser`, browserAgent, "Is Direct Call?", isDirectCall);
		
		if (browserAgent === "Chrome" || browserAgent === "Opera")
		{
			setToastMsz("") // CLEAR TOAST.
			
			console.log (`call_debug: checking permissions`);
			
			// DIRECT CALL NEED PERMISSION FOR CAMERA AND MICROPHONE, AND NORMAL CALL NEED PERMISSION FOR MICROPHONE.
			const requiredPermissions = isDirectCall	? ["camera", "microphone"]: ["microphone"];
			
			// CHECK PERMISSION FOR CALL
			const permissions = await checkPermission();
			console.log (`call_debug: permissions`, permissions);
			const permissionsMap = Object.fromEntries (
				permissions.map (({ name, permission }: any) => [name, permission])
			);
			
			console.log(`call_debug: permissionsMap`, permissionsMap);
			
			const granted = requiredPermissions.every (name => permissionsMap[name] === "granted");
			const denied = requiredPermissions.some (name => permissionsMap[name] === "denied");
			const prompt = requiredPermissions.some (name => permissionsMap[name] === "prompt");
			console.log(`call_debug: granted`, granted, `denied`, denied, `prompt`, prompt);
			if (granted)
			{
				console.log (`call_debug: permissions granted, Now starting call`);
				setHavePermissions ("granted");
				await startCall(abc);
			} else if (denied)
			{
				setHavePermissions ("denied");
				console.log (`call_debug: permissions denied. Need to reset permissions`);
				setToastMsz(
					`We need access to your ${isDirectCall? "camera and microphone" : "microphone"}.
					Click the LOCK icon in your browser's address bar >
					click on permission > reset permissions > refresh page `
				);
			} else if (prompt)
			{
				console.log (`call_debug: prompt permissions. Asking user to allow permissions.`);
				setHavePermissions ("prompt");
				setToastMsz ("Please allow camera and microphone permission whenever asked.");
				
				const constraints = isDirectCall
					? {
						video: {
							width: { min: 300, max: 640 },
							height: { min: 200, max: 480 },
						},
						audio: true,
					}
					: { audio: true };
				
				const success = await requestMediaPermissions(constraints);
				if (success) await startCall(abc);
			}
		} else
		{
			console.log(`call_debug: Browser not Chrome or Opera`);
			await startCall(abc);
		}
	};
	
	const requestMediaPermissions = async (constraints: any) =>
	{
		try
		{
			await navigator.mediaDevices.getUserMedia (constraints);
			setToastMsz("");
			toast.dismiss();
			setHavePermissions ("granted");
			return true;
		}
		catch
		{
			setToastMsz ("Failed to access media devices.");
			return false;
		}
	};
	
	return (
		<>
			{/* CONDITION 1: NO ICE SERVER CONFIGURATION */}
			{isLoaded && typeof webrtcConfig !== "undefined" && havePermissions === "denied" && (
				<CommonPage
					displayDiv={
						<div className="text-center thanks_text">
							<img src={logo} alt="Logo" />
							<h3 className="mt-5 pt-5">
								{`We need access to your camera and microphone.
								Click the LOCK icon in your browser's address bar >
								click on permission > reset permissions > refresh page`}
							</h3>
						</div>
					}
				/>
			)}
			
			{/* CONDITION 1: NO ICE SERVER CONFIGURATION */}
			{isLoaded && typeof webrtcConfig === "undefined" && (
				<CommonPage
					displayDiv={
						<div className="text-center thanks_text">
							<img src={logo} alt="Logo" />
							<h2 className="mt-5 pt-5">
								No ICE server configuration found. Please check your network settings or contact support for assistance.
							</h2>
						</div>
					}
				/>
			)}
			
			{/* CONDITION 2: VALID ICE SERVER CONFIGURATION */}
			{isLoaded && typeof webrtcConfig !== "undefined" && browserAgent && isEnabledDirectCall == 0 && (
			<>
				{
					detail_token_decoded?.qrcodeStatus === "active" &&
					Math.round(Date.now()) > token_decoded.iat ?
						(
							<div className="main-wrap">
								<div className="container">
									<div className="row">
										<div className="col-xxl-4 col-lg-5 col-md-6 m-auto text-center">
											<a>
												<img src={logo} alt=""/>
											</a>
											<h3 className="mt-5 pt-5">
												<span className="d-block">Enter Your</span> Booking Details
											</h3>
											<div className="mt-5 pt-5">
												<Formik
													initialValues={initialValues}
													validationSchema={validationSchema}
													onSubmit={checkMedia}
												>
													{(formik) =>
													{
														return (
															<Form onSubmit={formik.handleSubmit} autoComplete="off">
																{token_decoded?.fields?.length > 0 && token_decoded?.fields?.map ((e) =>
																{
																	return (
																		<QrUserDetailsInput
																			key={Object.keys (JSON.parse (e))}
																			type={Object.values (JSON.parse (e))}
																			label={Object.keys (JSON.parse (e))}
																			labelClassName="input-text"
																			name={Object.keys (JSON.parse (e))}
																			placeholder=" "
																		/>
																	)
																})}
																
																<button
																	type="submit"
																	className="btn btn-primary w-100 color-primary mt-50"
																>
																	<img src={call} alt=""/> Call
																</button>
															</Form>
														)
													}}
												</Formik>
											</div>
										</div>
									</div>
								</div>
							</div>
						)
						: Math.round (Date.now ()) < token_decoded.iat
							? (
								//if scan qr before time
								<CommonPage
									displayDiv={
										<>
											<div className="text-center thanks_text">
												<img src={logo} alt=""/>
												<h2 className="mt-5 pt-5">
													This Qr code will be available after
													<span className="d-block">
							  {moment (token_decoded.iat).utcOffset (userUtcOffset).format ("DD/MM/yyyy HH:mm")}
							</span>
												</h2>
											</div>
										</>
									}
								/>
							)
							: (
								//qr expire/invalid
								<CommonPage
									displayDiv={
										<>
											<img src={InvalidImage} alt="" className="image_call"/>
											<div className="text-center thanks_text">
												<img src={logo} alt=""/>
												<h2 className="mt-5 pt-5">
													{!token_decoded ? "QR Code" : "Invalid QR"}
													<span className="d-block">{!token_decoded ? "Expired" : "Code"}</span>
												</h2>
											</div>
										</>
									}
								/>
							)
				}
			</>
			)}
			
			{toastMsz && renderToast ()}
		</>
	)
}

export default UserScanQrCode
