// STORE THE LOGS IN AN ARRAY AND PRINT THEM ON TERMINAL.
import { globals } from "../utils/globals";

// PRINT THE MESSAGE ON CONSOLE.LOG AND STORE IT TO BE SENT TO SERVER IF IT'S REQUIRED.
export const createLog = (...args: any[]) =>
{
	let maxLogRows = 30;
	console.log ("max allowed rows are ",maxLogRows);

	// LOG LIST WILL CONTAIN MAXIMUM OF X ROWS.
	if (globals.logList.length > maxLogRows)
	{
		globals.logList.shift();
	}

	// LOOP THROUGH THE ARRAY AND IF SOMETHING IS NOT STRING THEN MAKE IT STRING.
	args = args.map ((arg) =>
	{
		if (typeof arg === "object")
		{
			return JSON.stringify (arg);
		}
		else
		{
			return arg + "";
		}
	});
	let logString = args.join (", ");
	let dataToSend: any = {l: logString};
	let type: string = "log";

	// GET THE TYPE OF THE LOG. IT CAN BE AN ERROR OR WARN.
	if (args.includes ("error"))
	{
		console.error (logString);
		dataToSend ['t'] = "e";
	}
	else if (args.includes ("warn"))
	{
		dataToSend ['t'] = "w";
		console.warn (logString);
	}
	else
	{
		console.log (logString);
	}
	globals.logList.push ({l: logString, t: type});
}
