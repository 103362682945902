// NOT IN USE. SHALL BE DELETED.

import {useContext, useEffect, useState} from "react"
import VideoContext from "./VideoContext"
import Micoff from "../../../_metronic/helpers/svg-helper/Micoff"
import Videoon from "../../../_metronic/helpers/svg-helper/Videoon"
import Videooff from "../../../_metronic/helpers/svg-helper/Videooff"
import PhoneHangup from "../user-scan-qrcode/images/phone-hangup.svg"
import CameraFlip from "../../../_metronic/helpers/svg-helper/CameraFlip"
import Micon from "../../../_metronic/helpers/svg-helper/Micon"
import VideoCall from "../../../_metronic/helpers/svg-helper/videocall"
import SpeakerOn from "../../../_metronic/helpers/svg-helper/speakeron"
import SpeakerOff from "../../../_metronic/helpers/svg-helper/speakeroff"
import {useSelector} from "react-redux"
import {globals} from "../../utils/globals"
import {v4 as uuidv4} from "uuid"
import {convertTimerFormat, socket} from "../../services/helper.service"

const VideoCallView = () =>
{
    const
    {
        switchCam,
        isSafariBrowser,
        sec,
        myVideo,
        userVideo,
	    userAudio,
	    stream,
		remoteUsername,
        isMyVideoOn,
        userVdoStatus,
        myMicStatus,
        userMicStatus,
        updateMic,
		leaveCall,
		switchLoudSpeaker,
		toggleVideoCall,
		phoneSpeaker,
		callType,
		isCallStatus,
		isItVideoCall,
		supportsMultipleCameras,
		isDirectCallEnabled,
		isCallConnected,
		isHideOptionButtons
    } = useContext(VideoContext)

	// HOLD THE USER DATA FROM REDUCER.
	const user = useSelector ((state: any) => state?.AuthReducer?.userData)
    const [username, setUsername] = useState ("") // USERNAME

	// HOOK TO JOIN THE RO0M REMOTE USER
	useEffect(()=>
	{
		// CALL ACCEPTED BY CALLEE. THEN SET THE CALLER NAME.
        if (isCallStatus == "IN")
        {
            try
            {
                let callerDetails = globals?.callerDetail;
                console.log ("callerDetails", callerDetails)
                let name = callerDetails?.name || callerDetails?.email || callerDetails?.phone;
                console.log ("username namename", name);
				name =  name?.match (/^(.+)@/) ?
						name?.match (/^(.+)@/)[1] : name
                setUsername (name);
            }
            catch (e)
            {
                console.error ("error in setting username", e)
            }
        }

		if (user && isCallStatus == "IN")
		{
			let call_id = globals.call_id;

			// USER OBJECT OF CURRENT USER TO CONTAIN STATIC FIELDS ONLY.
			globals.localUser =
            {
                room_id: call_id,
                origin: "MOBILE", // FROM WHERE THIS USER IS JOINING FROM? MOBILE APP OR WEB?
                ...user, // CURRENT USER.
                stream_id: (stream && typeof stream.id !== "undefined" ? stream.id : uuidv4().toString() + ""),
                socket_id: (socket && typeof socket.id !== "undefined" ? socket.id : null)
            }
			socket.emit ("join_room", globals.localUser); // LET'S JOIN THE ROOM.
		}
	},[user, isCallStatus]);

	return (
		<>
			{/* VIDEO */}
			<div className="VideoCall">
			    <div className="call-desk-input"  style={{display: !isDirectCallEnabled.current && isMyVideoOn ? "block" : "none"}}>
				    <video
                        id={"localVideo"}
                        style={{width: "100%", pointerEvents: "none" }}
					    controls={isSafariBrowser}
					    ref={myVideo}
					    autoPlay
					    playsInline
					    muted
				    ></video>
			    </div>

				{
                    !userMicStatus && (
					<div className="muteinfo-wrap" style={{display: `${userVdoStatus && !isDirectCallEnabled.current ? "block" : "none"}`}}>
						<Micoff />
						<span className="mute-info">
                          <span className="text-capitalize">
                              {
                                  remoteUsername && remoteUsername !== "" &&
                                  remoteUsername.match (/^(.+)@/) &&
                                  remoteUsername.match (/^(.+)@/)[1]
                              }
                              {username && username !== "" && username}
                            </span>{" "}
							has muted the call
                        </span>
					</div>)
                }

				{/* REMOTE USER VIDEO STREAM */}
				<div className="call-input" style={{display: `${userVdoStatus ? "block" : "none"}`}}>
					<div className="video-tag">
						<video
							id={"remoteVideo"}
							style={{height: "100vh", width: "100%", objectFit: "contain"}}
							ref={userVideo}
							autoPlay
							playsInline
						/>
						<audio
							id={"remoteAudio"}
							ref={userAudio}
							autoPlay
							playsInline
						/>
					</div>
				</div>
			</div>

			{/* Audio */}
			{!userVdoStatus && (
				<div className="AudioCall">
					<div className="audio-main">
						<div className="call-icon">
                            {username && username?.charAt(0).toUpperCase()}
                            {remoteUsername && remoteUsername?.charAt(0).toUpperCase()}
                        </div>
                        <h3>
                            {
                                remoteUsername && remoteUsername !== "" &&
                                remoteUsername.match (/^(.+)@/) &&
                                remoteUsername.match (/^(.+)@/)[1].toUpperCase()
                            }
                            {username && username !== "" && username.toUpperCase()}
                        </h3>
						{userMicStatus === false && <span className="mute-info fs-2">Muted</span>}
                        {remoteUsername && <small>{remoteUsername}</small>}
						<div className="timer">
							{/*{sec ? new Date(sec * 1000).toISOString().slice(11, 19) : "Connecting..."}*/}
							{sec ? convertTimerFormat(sec) : "Connecting..."}
						</div>
					</div>
				</div>
			)}

			{/* actions */}
			<div className="call-action">
				<div className="d-flex">
					<div className="camera-action d-flex flex-column ps-5">
						{
							!isHideOptionButtons && isCallConnected && (
							<>
								<div className="camera-action d-flex flex-column ps-5">
									<a className="btn-md-primary pointer mt-3" onClick={() => toggleVideoCall()}>
										{isMyVideoOn ? <Videoon />  : <Videooff />}
									</a>
								</div>
							</>
						)}
					</div>
					<a className="btn btn-cut m-auto mb-0 pointer" onClick={() => leaveCall (false)}>
						<img src={PhoneHangup} alt="" />
					</a>
					<div className="audio-action d-flex flex-column pe-5">
						{!isHideOptionButtons && isCallConnected && (
						<>
							{/*{callType === 'video' && supportsMultipleCameras && isMyVideoOn && localVideoStream?.getVideoTracks()[0] && (*/}
							{/*	<a className="btn-md-primary pointer" onClick={() => switchCam()}>*/}
							{/*		<CameraFlip />*/}
							{/*	</a>*/}
							{/*)}*/}
							{/*{*/}
							{/*	(callType=== 'audio' || !isMyVideoOn) &&*/}
							{/*    <a className="btn-md-primary" onClick={() => switchLoudSpeaker()}>*/}
							{/*		{phoneSpeaker == 'phone'? <SpeakerOn /> : <SpeakerOff />}*/}
							{/*    </a>*/}
							{/*}*/}
							{
								stream?.getAudioTracks()?.length > 0 && (
									<a className="btn-md-primary pointer mt-3" onClick={() => updateMic()}>
										{myMicStatus ? <Micon /> : <Micoff />}
									</a>
								)
							}
						</>)
					}
					</div>
				</div>
			</div>
		</>
    );
};

export default VideoCallView;
