// MAIN ROOM WRAPPER. IT CONTAINS ALL THE LOGIC NEEDED TO CONNECT OUTGOING OR INCOMING CALLS.
// IT WILL ENABLE USER TO TALK WITH THE REMOTE USER.
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VideoContext from "./VideoContext";
import store from "../../../_metronic/redux/store";
import { globals } from "../../utils/globals";
import { getLoggedInUser } from "../../services/login.service";
import { useSelector } from "react-redux";
import
{
	insertCallRecordIntoFirestore, socket,
	updateCallStatus,
	updateCallStatusByUserId,
} from "../../services/helper.service";
import { v4 as uuidv4 } from "uuid";
import { database } from "../../services/firebase.service";
import { child, off, onValue, ref } from "firebase/database";
import { httpRequest } from "../../services/network.service";
import {caller} from "moment";
import {
	closeUserPeerConnections,
	getPeerConnection,
	onIceCandidateOffer,
	processPendingCandidates, sendOfferToRemoteUser,
	setPeerConnection,
	constraints, isThereVideoInPeer, removeStoppedTrackFromPeerConnection
} from "./peer.service";
import {createLog} from "../../services/logger.service";


let pingInterval;

const VideoState = ({children, isCallStatus}) =>
{
	// GET THE CURRENT LOCATION AND STATE FROM REACT ROUTER
	const location = useLocation();
	const {state} = location;

	// GET THE NAVIGATE FUNCTION FROM THE REACT ROUTER HOOK
	const navigate = useNavigate();
	const isDirectCallEnabled = useRef (isCallStatus == "IN"? (globals.enabledDirectCall == 1) :  state?.enabledDirectCall == 1? true:false);
	const callerCode = useRef (isCallStatus == "IN"? "Staff": "Caller");
	
	// SET IT TO FALSE BECAUSE BY DEFAULT, ALL CALLS ARE AUDIO CALLS. EVEN THE VIDEO CALLS WILL START AS AUDIO, BUT
	// LATER THEY WILL BE CONVERTED INTO VIDEO CALL.
	const isItVideoCall = useRef (isDirectCallEnabled.current);
	const [callType, setCallType] = useState (isItVideoCall.current ? "video" : "audio");

	// EXTRACT THE EMAIL FROM THE REDUX STORE STATE USING THE USESELECTOR HOOK
	const email = useSelector((state) => state?.AuthReducer?.email);
	const user = useSelector((state) => state?.AuthReducer?.userData)

	const [call, setCall] = useState({})
	const [callAccepted, setCallAccepted] = useState(false)
	const [stream, setStream] = useState(null)
	const [remoteUsername, setRemoteUsername] = useState("")
	const [callEnded, setCallEnded] = useState(false)
	const [me, setMe] = useState("")
	const [username, setUsername] = useState("")
	const [remoteUserSocketId, setRemoteUserSocketId] = useState (null)
	const [startCall, setStartCall] = useState (false)
	const [callId, setCallId] = useState(null);
	const [callStartTime, setCallstarttime] = useState(null);
	const [isMyVideoOn, setIsMyVideoOn] = useState (isItVideoCall.current);
	const [userVdoStatus, setUserVdoStatus] = useState (false)
	const [myMicStatus, setMyMicStatus] = useState(true)
	const [userMicStatus, setUserMicStatus] = useState()
	const [staffUnavailable, setStaffUnavailable] = useState(false)
	const [message, setMessage] = useState ("")
	const [callRejected, setcallRejected] = useState()
	const [isSafariBrowser, setisSafariBrowser] = useState(false)
	const [webrtcState, setwebrtcState] = useState("Connecting...");
	const [callStatus, setCallStatus] = useState ("");
	const [isCallConnected, setIsCallConnected] = useState (false);
	const [frontCam, setfrontCam] = useState (true)
	const [phoneSpeaker, setPhoneSpeaker] = useState ("phone");
	const [supportsMultipleCameras, setSupportsMultipleCameras] = useState (false);
	const [isHideOptionButtons, setHideOptionButtons] = useState (false);
	const [ICEServers, setICEServers] = useState (null);
	const [turnOnVideo, setTurnOnVideo] = useState (false);
	
	const myVideo = useRef()
	const userVideo = useRef()
	const userAudio = useRef()
	const connectionRef = useRef()
	const call_detail_id = useRef()
	const localStream = useRef(null)
	const isFirstCall = useRef(null)

	// AUDIO CALL SECONDS
	const [sec, setSec] = useState(0)
	const [isRunning, setRunning] = useState(false)

	// TIMEOUT IF CALL NOT RECEIVED
    const remainingTimeRef = useRef (90000); // INITIAL TIMER: 90 SECONDS (90000MS)
    const timerRef = useRef(null);
	const [staffTimeOut, setstaffTimeOut] = useState(false)

	const handleStartButton = () =>
	{
		setRunning (true)
	}
	const handleResetButton = () =>
	{
		setSec(0)
		setRunning(false)
	}

	// THIS HOOK TO USE THE CALL TIMER START WHEN CALL IS CONNECTED.
	useEffect(() =>
	{
		if (isRunning)
		{
			const intervalId = setTimeout(() =>
			{
			    setSec ((prevCount) => prevCount + 1)
			}, 1000)

			// REMOVE TIMER INTERVAL.
		    return () =>
		    {
		        clearTimeout (intervalId)
		    }
		}
	}, [sec, isRunning])
	
	
	// SOCKET EVENTS
	useEffect(() =>
	{
		try
		{
			// FOR SAFARI
			let userAgent = navigator.userAgent
			if (userAgent.match(/safari/i)) { setisSafariBrowser(true) }
			
			startCallOutTimer (remainingTimeRef.current); // 90 SEC TIMER
			
			if (isDirectCallEnabled.current && isCallStatus === "OUT")
			{
				setHideOptionButtons (true);
			}
			
			// SUBSCRIBE THE SOCKET EVENTS.
			subscribeSocketEvent();
			
			window.addEventListener('beforeunload', () =>
			{
				socket.disconnect();
			});
		}
		catch (e)
		{
			console.log ("Error in VideoState useEffect", e);
			let reason = callerCode.current +" have error on peer connections. Please try again.";
			leaveCall (reason, true)
		}
		return ()=>
		{
			
			window.addEventListener('beforeunload', () =>
			{
				socket.disconnect();
			});
			// TERMINATE THE CALL CONNECTION.
			terminateCallConnection();
			clearTimeout (timerRef.current);
			clearInterval(pingInterval);
		}
	}, []);
	
	const handleConnectionStateChangeEvent = (event, peerConnection) =>
	{
		createLog ("webrtc_debug", "connection state change event: ", peerConnection.connectionState);
		if(peerConnection.connectionState === "connected")
		{
			setIsCallConnected (true);
		}
		webrtcState !== peerConnection.connectionState && setwebrtcState (peerConnection.connectionState)
	}
	
	const handleIceConnectionStateChangeEvent = (event) =>
	{
		createLog ("webrtc_debug", "ice connection state change event: ", event.target.iceConnectionState);
		
		if (event.target.iceConnectionState === "disconnected" || event.target.iceConnectionState === "failed")
		{
			createLog ("webrtc_debug","warn","ICE connection state is unstable. Attempting to restart ICE...");
			sendOfferToRemoteUser (remoteUserSocketId, remoteUsername, me, location, true).then();
		}
	}
	
	const handleOnTrackEvent = (event, remote_user_id) =>
	{
		// IF ITS THE CALLER AND THE TRACK IS VIDEO THEN DONT DO ANYTHING.
		// if (isCallStatus === "OUT" && event.track.kind === "video")
		// {
		// 	return;
		// }
		// IF EVENT IS AUDIO THEN USE AUDIO ELEMENT.
		const element = event.track.kind === "audio"  ? userAudio : userVideo;
		
		// HERE WE GOT REMOTE STREAM
		createLog ("webrtc_debug", "on track event fired.");
		console.log ("webrtc_debug", "got new track", event.track.kind, element.current);
		handleStartButton();
		let remoteUserStream = new MediaStream ([event.track]);
		createLog ("webrtc_debug: create new stream");
		
		// IF ELEMENT NOT FOUND THEN TRY TO GET IT BY ID.
		if (!element.current)
		{
			createLog ("webrtc_debug", "element was not found. trying to get it by id", "warn");
			element.current = document.getElementById (event.track.kind === "audio" ? "remoteAudio": "remoteVideo");
		}
		element.current.srcObject = remoteUserStream;
		try
		{
			element.current.play();
		}
		catch (e)
		{
			createLog ("webrtc_debug", "error in playing the remote stream","error", e);
			// TODO: THIS ERROR IS MOST LIKE DUE TO NON-INTERACTION WITH THE BROWSER. SO, WE ASK THE USER TO
			//  CLICK ON A BUTTON. DISPLAY A JOIN BUTTON SO USER CAN CLICK ON IT. CLICKING THAT BUTTON WILL TRY
			//  TO PLAY THE TRACK AGAIN.
		}
			// document.getElementById ("remoteVideo").srcObject = remoteUserStream;
	}
	

    // FUNCTION TO START THE TIMER
    const startCallOutTimer = (time) =>
    {
        clearTimeout (timerRef.current); // CLEAR ANY EXISTING TIMER
        timerRef.current = setTimeout(() =>
        {
            console.log (">>>>>>>>>>>>>>>>>> Timer ended:", time);

            // IF CALLER THEN UPDATE CALL STATUS TO MISSED.
            if (call_detail_id.current)
			{
                // UPDATE CALL STATUS TO MISSED
                updateCallStatus (call_detail_id.current, "MISS").then(async () =>
                {
                    // INSERT CALL DETAIL IN FIRESTORE.
                    await insertCallRecordIntoFirestore (call_detail_id.current);
                });
				socket.emit ("endCall", remoteUserSocketId)
				
				//  NAVIGATE TO STAFF NOT AVAILABLE PAGE.
                navigate ("/staffnotavailable",
                {
                    state:
                    {
                        userinfo: state?.userInfo,
                        reason: 'MISS',
                        call_id: call_detail_id.current,
                    },
                })
                return;
            }
            setstaffTimeOut (true);
        }, time);
    };
	
	const updateIceServers = (servers) =>
	{
		createLog ("webrtc_debug", "got ice servers", servers);
		setICEServers (servers);
	}
	
	const handleMessages = (data, callback) =>
	{
		console.log ("send message", data);
		switch (data.command)
		{
			case "start_call":
				initiateCall (data);
				callback ("call will be started.")
			break;
		}
	}
	
	const handleMessagesResponse = (response) =>
	{
		console.log ("Response for send message received from remote user", response);
	}

	// SOCKET EVENTS
	const subscribeSocketEvent = ()=>
	{
		createLog ("webrtc_debug", "subscribed to socket events", "next: " + (isCallStatus === "OUT" ? "dial call" : "initiate call"), socket.id);
		
		// YOUR SOCKET ID FOR BOTH (OUTGOING-CALL AND INCOMING-CALL)
		//socket.emit ('getMySocketId', null, getMyId);
		setMe (socket.id);
		// socket.emit ('getIceServersDetail', null, updateIceServers)

		// IF THIS IS A CALLER THEN START THE CALL.
		if (isCallStatus === "OUT" || state.callingStatus === "O")
		{
			createLog ("webrtc_debug", "dial call", "next: get Call IdFrom Server");
			
			// SEND A CALL REQUEST TO SERVER. SERVER WILL SEND CALL NOTIFICATION TO STAFF.
			socket.emit ("dial-call", state?.staffToCall, state?.userInfo, state);
			
			// AFTER "DIAL CALL" SOCKET EVENT, SERVER WILL SEND THE CALL TO STAFF AND RETURN THE CALL ID TO THE CALLER.
			socket.on ("getCallIdFromServer", getCallIdFromServer);
		}
		else // THIS IS CALL RECEIVER. SO THE CALL ID WILL BE AVAILABLE FROM GLOBALS.
		{
			createLog ("webrtc_debug", "call receiver, got the call id", globals.call_id);
			setCallId (globals.call_id) // SET STATE.
			call_detail_id.current = globals.call_id;
		}
		
		socket.on ("send_message", handleMessages);
		socket.on ("send_message_response", handleMessagesResponse);

		// THIS EVENT EMITTED WHEN NO STAFF IS AVAILABLE FOR OUTGOING-CALL.
		socket.on ("staff-unavailable", handleStaffUnavailable);

		// THIS EVENT EMITTED WHEN STAFF ACCEPTED CALL FOR OUTGOING-CALL.
		socket.on ("staff-details", handleStaffDetail);

		socket.on ("offer", handleOffer);

		// THIS EVENT WILL BE TRIGGERED WHEN THERE IS ICE CANDIDATE INFORMATION TO BE SHARED FOR BOTH.
		socket.on ("offer-candidate", onIceCandidateOffer);

		// EVENT EMITTED WHEN ANYONE CHANGES THEIR CAMERA AND MIC STATUS FOR BOTH.
		socket.on ("updateUserMedia", handleUserMediaUpdate);

		// EVENT EMITTED WHEN STAFF ACCEPT CALL AND EMITTED ANSWER SDP FOR OUTGOING-CALL.
		socket.on ("answer", handleAnswer);

		// EVENT EMITTED WHEN CALL ENDED FOR BOTH.
		socket.on ("endCall", handleEndCall);
		socket.on ("errorOnCall", handleErrorOnCall)

		socket.on ('disconnect', function()
		{
			console.log ('debug: step 3.0 Socket disconnected');
		});

		socket.on ('reconnect_attempt', function(attemptNumber)
		{
			console.log (`debug: step 2.1 Reconnection attempt #${attemptNumber}`);
		});

		socket.on ('reconnect_failed', function()
		{
			console.log ('debug: step 2.2 Reconnection failed');
		});

		socket.on ('reconnect', function()
		{
			console.log ('debug: step 2.0 Successfully reconnected to the server');
		});
		socket.on ('connect',()=> {
			console.log ('debug: step 1.0 socket connected with main server:\n', socket);
		});

		socket.on ('connect_error',(err)=> {
			console.log (`debug: step 1.1 main server socket connect_error due to: ${err.message}`);
		});

		socket.on ('connect_timeout',()=> {
			console.log (`debug: step 1.2 main server socket connection timeout`);
		});

        socket.on ('timerIncrement', () =>
        {
            console.log ("Received timer increment event from socket");

            // INCREMENT REMAINING TIME BY 60 SECONDS (60000MS)
            const newRemainingTime = remainingTimeRef.current;// + 60000;

            // UPDATE REF
            remainingTimeRef.current = newRemainingTime;

            // RESTART TIMER WITH THE NEW REMAINING TIME
            startCallOutTimer (newRemainingTime);
        });
		
		socket.on('pong', () =>
		{
			console.log ('Pong received');
		});
	}
	
	// useEffect (() =>
	// {
	// 	console.log("debug remoteUserSocketId", remoteUserSocketId, callType);
	//
	// 	// CALL TYPE IS VIDEO THEN GET THE VIDEO STREAM ELSE AUDIO STREAM.
	// 	if (isDirectCallEnabled.current && turnOnVideo && stream)
	// 	{
	// 		console.log("debug calling the video call", remoteUserSocketId, callType);
	// 		initializeCamera().then();
	// 	}
	// }, [turnOnVideo, stream]);

	// WHEN CALL_ID IS AVAILABLE, THEN SUBSCRIBE TO THE CALL DETAIL FIRESTORE IN THE DATABASE. THIS WILL BE USED TO
	// LISTEN FOR CHANGES IN THE CALL STATUS.
	useEffect (() =>
	{
		// THIS SHALL ONLY WORK FOR CALLER.
		if (callId && isCallStatus === "OUT")
		{
			// REFERENCE TO 'CALL' NODE IN THE DATABASE
			const databaseRef = ref (database, 'call');
			const callRealTimeRef = child (databaseRef, callId);
			// const callStatusRef = child (callRealTimeRef, "callstatus");

			// LISTEN FOR CHANGES IN 'CALL' NODE
			onValue (callRealTimeRef, handleCallDetailSnapshot);

			// CLEAN UP SUBSCRIPTION WHEN COMPONENT UNMOUNTS
			return () =>
			{
				// UNSUBSCRIBE FROM DATABASE CHANGES
				// THIS ENSURES THAT WE DON'T HAVE ANY MEMORY LEAKS
				off (callRealTimeRef, 'value', handleCallDetailSnapshot);
			};
		}
	}, [callId]);

	// HANDLE SNAPSHOT UPDATES: IF THE CALL STATUS IS "ANSWERED" AND hasAnswered FLAG IS FALSE THEN
	// TERMINATE THE INCOMING CALL SCREEN.
	const handleCallDetailSnapshot = (snapshot) =>
	{
		const data = snapshot.val();

		let default_staff_id = state?.userInfo?.default_staff_id;
		let isDefaultStaffInStaffList = data?.isDefaultStaffInStaffList;
		
		console.log ("handleCallDetailSnapshot:", data.rejectedby_user_id, "isCallStatus", isCallStatus);
		console.log ("rejectedby_user_id", data?.rejectedby_user_id?.length, "staffids", data.staffids?.length, "isDefaultStaffInStaffList", isDefaultStaffInStaffList)

		if (data.callstatus === "CONNG")
		{
			setCallStatus ("We are connecting your call. Please wait . . .");
		}
		else if (data.callstatus === "RING")
		{
			setCallStatus ("Ringing");
		}
		else if (data.callstatus === "ACCPT")
		{
			setCallStatus ("We are about to connect you with an agent . . .");
			clearTimeout (timerRef.current);
		}
		
		// CHECK toHaveError FLAG IS TRUE THEN NAVIGATE TO ERROR PAGE AND SHOW THE ERROR MESSAGE.
		if (data.callstatus === "ENDED" && data.toHaveError && data.errorFrom != callerCode.current)
		{
			navigate ("/callErrorHandler", { state: { message: data.reason } });
			return;
		}
		
		// CHECK IF THE CALL STATUS IS 'ENDED' THEN TERMINATE THE CALL.
		if (data.callstatus === 'ENDED' && isCallStatus === "IN")
		{
			console.log ("call Ended by realtime database");
			
			// END THE CALL
			navigate ("/dashboard");
		}
		
		// CHECK IF CALL STATUS IS 'MISS' THEN TERMINATE THE CALL.
		if (data.callstatus === 'MISS' && isCallStatus === "IN")
		{
			console.log ("call MISSED by realtime database");
			
			// END THE CALL
			navigate ("/dashboard");
		}
		
		// IF CALL STATUS IS 'REJCT' AND OUTGOING CALLER THEN CHECK IF ALL STAFF ARE REJECTED THEN END THE CALL.
		if (typeof data.rejectedby_user_id !== "undefined" && isCallStatus === "OUT")
		{
			console.log("rejectedby_user_id", data.rejectedby_user_id.length, "staffids", data.staffids.length)

			// IF ALL REJECTED STAFF ARE NOT IN RINGING STATUS, END THE CALL
			if ( data.rejectedby_user_id.length === data.staffids.length)
			{
				// END THE CALL IF ALL STAFF REJECTED THE CALL.
				if (isDefaultStaffInStaffList)
				{
                    handleEndCall (true, callId, data.reason);
                }
			}
		}
		
		// IF DEFAULT STAFF RECEIVED THE SEPARATE NOTIFICATION THEN REJECT THE CALL OR MISS THE CALL, THEN END THE CALL.
		// FIRSTLY, WE CHECKED DEFAULT STAFF IS NOT IN THE STAFF LIST WHO RECEIVED THE CALL.
		if (!isDefaultStaffInStaffList)
		{
			const defaultStaffNotified = data?.defaultstaffnotified; // HOLD DEFAULT STAFF NOTIFIED ARRAY.
			console.log ("defaultStaffNotified", defaultStaffNotified);
			
			if (typeof defaultStaffNotified !== "undefined" && typeof defaultStaffNotified.callstatus !== "undefined")
			{
				console.log("defaultStaffNotified.callstatus", defaultStaffNotified.callstatus);
				
				// IF DEFAULT STAFF REJECTED THE CALL THEN END THE CALL.
				if (defaultStaffNotified.callstatus === "REJTD" || defaultStaffNotified.callstatuses.some (status => status.indexOf("REJTD") !== -1))
				{
					handleEndCall (true, callId, "REJCT");
					return;
				}
				
				// IF DEFAULT STAFF MISSED THE CALL THEN END THE CALL.
				if (defaultStaffNotified.callstatus === "REJTD" || defaultStaffNotified.callstatuses.some (status => status.indexOf("MISS") !== -1))
				{
					handleEndCall (true, callId, "MISSED");
					return;
				}
			}
		}
		console.log("data", data);

		// CHECK THE notified_user_id ARRAY LENGTH. IF IT'S GREATER THAN 0 THEN CHECK EACH INDEX OF callstatuses IS
		// MISSED ALL INDEX THEN END THE CALL.
		if (typeof data.notified_user_id !== "undefined" && isCallStatus === "OUT")
		{
			//console.log ("notified_user_id", Object.keys(data.notified_user_id)?.length, "staffids",
			// data.staffids.length)

			// HOLD MISSED STATUS COUNT.
			let missedStatusCount = 0;

			// ITERATE OVER THE NOTIFIED_USER_ID OBJECT
			Object.keys(data?.notified_user_id).forEach(userId =>
			{
				let userData = data.notified_user_id[userId];
			
				if (userData)
				{
					// CHECK IF ANY OF THE CALLSTATUSES CONTAIN "MISS"
					if (userData?.callstatuses?.some (status => status.indexOf ("MISS") !== -1))
					{
						missedStatusCount++;
					}
				}
			});
			console.log ("Number of staff missed the call", missedStatusCount);

			// IF ALL STAFF MISS THE CALL THEN END THE CALL.
			if (missedStatusCount === data.staffids?.length )
			{
				console.log ("All staff missed the call");
				
				// END THE CALL IF ALL STAFF REJECTED THE CALL.
				if (isDefaultStaffInStaffList)
				{
					handleEndCall(true, callId, "MISSED");
					return;
				}
			}
		}
	}

	// SET MY SOCKET ID ON STATE
	const getMyId = (data) =>
	{
		createLog ("webrtc_debug", "got my socket id", data, "next: getCallId FromServer");
		setMe (data) // SET STATE.
	}

	// SET CALL DETAIL ID ON STATE
	const getCallIdFromServer = (data) =>
	{
		createLog ("webrtc_debug", "got the call id", data, "next: join room");
		setCallId (data) // SET STATE.
		call_detail_id.current = data;

		// USER OBJECT OF CURRENT USER TO CONTAIN STATIC FIELDS ONLY.
		globals.localUser =
		{
			room_id: data,
			origin: "WEB", // FROM WHERE THIS USER IS JOINING FROM? MOBILE APP OR WEB?
			...state?.userInfo, // CURRENT USER.
			stream_id: (stream != null ? stream?.id : uuidv4().toString() + ""),
			socket_id: (socket && typeof socket?.id !== "undefined" ? socket.id : null)
		}
		createLog ("webrtc_debug", "emitting join room", "next: wait for staff to answer. Initiate Call");
		socket.emit ("join_room", globals.localUser); // LET'S JOIN THE ROOM.
		
		// Start ping-pong
		pingInterval = setInterval(() =>
		{
			console.log ('Sending ping...', socket?.id);
			socket.emit ('ping', socket?.id);
		}, 3000);
	}

	// THIS FUNCTION CALL WHEN STAFF UNAVAILABLE.
	const handleStaffUnavailable = async (data) =>
	{
		console.log ("handleStaffUnavailable", data);

		// MAKE SURE data IS A VALID OBJECT.
		if (data && typeof data === "object" && Object.keys(data).length > 0)
		{
			const { call_id, reason, callstatus } = data;

			// UPDATE THE CALL DETAIL BY CALL DETAIL ID.
			// await updateCallFirestore (call_id, { reason: reason, callstatus: callstatus });

			let fireStoreCall_id = call_id?call_id: callId;

			// IF CALLER THEN NAVIGATE TO STAFF NOT AVAILABLE PAGE.
			if (fireStoreCall_id)
			{
				navigate ("/staffnotavailable",
					{
						state:
						{
							userinfo: state?.userInfo,
							reason: reason,
							call_id: fireStoreCall_id,
						},
					}
				)
				return;
			}
			setStaffUnavailable (true); // STAFF IS NOT AVAILABLE

			setMessage (reason);
		}
		else
		{
			console.error ("staff unavailable. data is not valid", data);
		}
	}

	// THIS CODE WILL RUN FOR CALLER OF THE CALL.
	const handleStaffDetail =  (obj) =>
	{
		createLog ("webrtc_debug", "received staff details", obj, "next: initiate call will be triggered from" +
			" receiver side. and then this user will receive offer");
		clearTimeout (timerRef.current); // CLEAR ANY EXISTING TIMER
		let answerCallStaff = obj.answerCallStaff;
		setRemoteUsername (answerCallStaff.email || answerCallStaff.full_name)
		console.log ("set remote user socket id_1", answerCallStaff.socketId);
		setRemoteUserSocketId (answerCallStaff.socketId);
		Object.assign (location.state, {staffs: obj.staffs})
	}
	
	// POSSIBLE VALUES OF TYPE ARE "audio" | "video" | "both".
	const startStream = async (type) =>
	{
		let audioConstraints = (type === "audio" || type === "both" ? constraints.audio : false);
		let videoConstraints = (type === "video" || type === "both" ? constraints.video : false);
		try
		{
			const currentStream = await navigator.mediaDevices.getUserMedia
			({
				audio: audioConstraints,
				video: videoConstraints
			});
			let peerConnection = startPeerConnection (remoteUserSocketId);
			
			// IF BOTH AUDIO AND VIDEO ARE REQUIRED THEN GET IT.
			if (type === "both")
			{
				localStream.current = currentStream; // Replace the current stream
				currentStream.getTracks().forEach (track => peerConnection.addTrack (track, currentStream));
			}
			else
			{
				// IF STREAM ALREADY EXISTS, ADD THE NEW TRACK; OTHERWISE, INITIALIZE A NEW STREAM
				if (!localStream.current)
				{
					localStream.current = new MediaStream();
				}
				createLog ("webrtc_debug", `type is ${type} | ${localStream.current.getAudioTracks().length} | ${localStream.current.getVideoTracks().length}`);
				let track = null;
				
				if (type === "audio" && localStream.current.getAudioTracks().length === 0)
				{
					track = currentStream.getAudioTracks()[0];
				}
				if (type === "video" && localStream.current.getVideoTracks().length === 0)
				{
					createLog ("webrtc_debug", "extract video track");
					track = currentStream.getVideoTracks()[0];
				}
				
				// ADD THE TRACK TO THE LOCAL STREAM.
				if (track)
				{
					localStream.current.addTrack (track);
					peerConnection.addTrack (track, localStream.current);
					
					// VALIDATE THE VIDEO ELEMENT BEFORE SETTING THE SRC OBJECT.
					if (!isDirectCallEnabled.current && (type === "both" || type === "video") && myVideo.current)
					{
						createLog ("webrtc_debug", "display local video stream");
						myVideo.current.srcObject = new MediaStream ([track]);
					}
				}
			}
			setStream (localStream.current);
		}
		catch (error)
		{
			console.error("Error starting stream:", error);
			let reason = "Sorry, something went wrong. Please refresh the screen and try again.";
			
			// CALL ENDED IF ERROR OCCURS.
			leaveCall (reason, true);
		}
	};
	
	
	// INITIALIZE THE BOTH AUDIO AND VIDEO STREAM.
	const startVideoCall = async () =>
	{
		// TRY TO GET THE AUDIO STREAM.
		try
		{
			await startStream ("both");
			sendOfferToRemoteUser (remoteUserSocketId, "video", remoteUsername, me, location).then();
			createLog ("webrtc_debug", "Added video & audio tracks to PC", "next: handle negotiation");
		}
		catch (e)
		{
			console.error ("error in getting video stream", e);
			let reason = callerCode.current + " video stream not available.";
			
			// CALL ENDED IF ERROR OCCURS.
			leaveCall (reason, true);
		}
	}
	
	useEffect (() =>
	{
		// ON THE CALLER SIDE, WHEN THE STAFF USER'S REMOTE ID IS AVAILABLE THEN SEND A COMMAND TO
		// RECEIVER TO START THE CALL.
		if (remoteUserSocketId)
		{
			createLog ("webrtc_debug", `remote user id is: ${remoteUserSocketId} & call direction is: ${isCallStatus}`);
			if (isCallStatus === "OUT")
			{
				let postData = {
					to: remoteUserSocketId,
					command: "start_call",
					socket_id: socket.id
				};
				createLog ("webrtc_debug", "emit send message with data ", postData)
				socket.emit ("send_message", postData);
			}
			else // THIS IS RECEIVER/STAFF. IF THERE IS A COMMAND TO START_CALL THEN DO IT.
			{
				if (startCall)
				{
					processStartCall().then();
				}
			}
		}
	}, [remoteUserSocketId, startCall]);
	
	const processStartCall = async () =>
	{
		createLog ("webrtc_debug", "call process started. ", "next: start " +(isDirectCallEnabled.current ? "video": "audio") + " call");
		if (isDirectCallEnabled.current)
		{
			await startVideoCall();
		}
		else
		{
			await startAudioOnlyCall();
		}
	}

	// THIS WILL BE CALLED FROM SOCKET EVENT "initiate-call". IT WILL BE TRIGGERED FROM "call-answered" FROM SERVER.
	// THIS FUNCTION WILL RECEIVE THE SOCKET ID OF THE CALLER AKA REMOTE USER.
	// THIS WILL BE EXECUTED FOR THE CALLEE/STAFF WHO HAS ANSWERED THE CALL.
	const initiateCall = (data) =>
	{
		createLog ("webrtc_debug", "initiate call", data, "next start call process");
		setRemoteUserSocketId (data.socket_id);
		setStartCall (true);
		
		// START THE CALL CONNECTION WHEN REMOTE USER'S SOCKET ID IS AVAILABLE.
		if (data.socket_id)
		{
			console.log ("remote user's socket id is available now", data.socket_id);
		}
		else
		{
			// TODO: SHOW ERROR TO USER.
			console.error ("remote user's socket id is not available");
		}
	}
	

	// FUNCTION TO HANDLE TO BOTH FUNCTIONALITY INCOMING-CALL AND OUTGOING-CALL.
	// INCOMING-CALL | SET THE CALL STATE CALLER INFO AND ANSWER SIGNAL.
	// OUTGOING-CALL | HANDLE THE CALL OFFER FROM REMOTE USER. REMOTE USER WILL CREATE AN OFFER, AND THE CURRENT USER
	// WILL SEND AN ANSWER TO CALLEE.
	const handleOffer = async (data) =>
	{
		createLog ("webrtc_debug", "got the offer", "next: send answer to receiver.");
        if (data.signal.type  === "offer")
        {
			let peerConnection = startPeerConnection (data.from);
			
			// IF TIMER IS RUNNING THEN CLEAR THE TIMER OUT REFERENCE.
			if (timerRef.current)
            clearTimeout (timerRef.current);

			// CREATE ANSWER FOR INCOMING CALL.
	        try
	        {
		        // CHECK IF PEER CONNECTION EXISTS.
		        if (peerConnection)
		        {
			        // CREATE THE OFFER RTCSessionDescription THEN STORE THE REMOTE DESCRIPTION.
			        try
			        {
				        // START CALL.
				        createLog ("webrtc_debug", "got offer. now start new call", data.from);
				        // await processStartCall();
				        
				        // IF THIS USER HAS NOT YET START A CALL THEN START THE STREAM FOR THIS USER.
				        if (localStream.current === null || typeof localStream.current === "undefined")
				        {
					        isFirstCall.current = true;
					        if (isDirectCallEnabled.current)
					        {
						        await startStream ("both");
					        } else
					        {
						        await startStream ("audio");
					        }
				        }
						else
				        {
							createLog ("webrtc_debug", "local stream is not null. Dont start anything.")
					        isFirstCall.current = false;
				        }
						
						// DEBUG_AUDIO. NO ISSUE TILL HERE.
						
				        // CREATE THE OFFER RTCSessionDescription THEN STORE THE REMOTE DESCRIPTION.
				        let offerRemoteUser = new RTCSessionDescription
				        ({
					        type: 'offer', // SPECIFY THE TYPE OF THE SESSION DESCRIPTION (OFFER/ANSWER)
					        sdp: data.signal.sdp   // THE SDP STRING
				        });
						console.log ("offerRemoteUser",offerRemoteUser);
						
				        // SET THE REMOTE DESCRIPTION.
				        await peerConnection.setRemoteDescription (offerRemoteUser);
				        
				        console.log ("Remote description set successfully on caller side.");
				        processPendingCandidates (peerConnection);
			        }
			        catch (e)
			        {
				        createLog ("webrtc_debug", "error in handling offer", "error", e);
			        }
			        
			        // TRY TO CREATE THE ANSWER AND SEND IT TO A REMOTE USER.
			        try
			        {
				        let answer = await peerConnection.createAnswer (); // TBD
				        await peerConnection.setLocalDescription (answer);
				        
				        // AUDIO_DEBUG. AUDIO ISSUE HERE.
				        const hasVideoTrack = isThereVideoInPeer (peerConnection);
				        
				        let user_email = email;
				        if (!user_email)
				        {
					        // GET CURRENT USER'S PROFILE DATA.
					        let user = await getLoggedInUser();
					        user_email = user?.email
				        }
				        let userSocketId = me? me: socket.id;
				        
				        // SET THE ANSWER OBJECT AND SEND IT TO A REMOTE USER.
				        const payload =
				        {
					        to: data.from , // SOCKET ID OF REMOTE USER.
					        caller: userSocketId, // SOCKET ID OF CURRENT USER.
					        signal: {type: 'answer', 'sdp': answer.sdp}, // SESSION DESCRIPTION PROTOCOL INFORMATION.
					        userName: "",
					        type: (!hasVideoTrack) ? "mic" : "both",
					        'myMediaStatus': (!hasVideoTrack) ? true : [true, true],
					        staffs: data.staffs,
					        email: user_email,
					        callType: hasVideoTrack ? "video" : "audio",
				        }
				        
				        console.log ("webrtc_debug answer created, sending to remote", payload, remoteUserSocketId, data.from);
				        socket.emit ("answer", payload) // SEND DATA TO REMOTE USER.
				        globals.callStartTime = new Date();
			        }
			        catch (e)
			        {
				        console.error ("webrtc_debug error in creating answer.", e);
			        }
		        }
		        else
		        {
			        console.error ("peer not connected");
		        }
	        }
	        catch (error)
	        {
		        console.error ("webrtc_debug handle call offer error ", error);
	        }
            console.log ("SOCKET_ON_CALL_USER_EVENT => get offer", "outgoing caller create answer");
        }

        if (isCallStatus === "OUT")
		{
            const {from, name: callerName, signal} = data;
			setCall({isReceivingCall: true, from, name: callerName, signal})
		}
	}
	
	const handleAnswer = async (data) =>
	{
		setCallAccepted (true);
		setCallstarttime (new Date());
		
		// IF TIMER IS RUNNING THEN CLEAR THE TIMER OUT REFERENCE.
		if (timerRef.current) clearTimeout (timerRef.current);
		
		const mediaData = data.data;
		createLog ("webrtc_debug", "handle answer ", "next: exhcnage offer/answer -> ontrack");
		let peerConnection = getPeerConnection();
		console.log ("answer sdp data from socket", mediaData.signal, data.socket_id)
		
		if (peerConnection.signalingState === 'stable')
		{
			createLog ("Remote description already set, call may not work.", "error");
		}
		
		try
		{
			await peerConnection.setRemoteDescription (new RTCSessionDescription(mediaData.signal));
			console.log ("Remote description set successfully.");
			processPendingCandidates (peerConnection);
		}
		catch (error)
		{
			createLog ("Error setting remote description", "error", error);
		}
	};

	const handleEndCall = async (reject, call_id, rejectionDetail) =>
	{
		console.log ("reject", reject, call_id, rejectionDetail)
		setcallRejected (reject)
		if (reject)
		{
			// CREATE THE OBJECT FOR UPDATE THE CALL REJECTION REASON.
			let column = {"reason" : rejectionDetail.type};
			let updateID = call_id || rejectionDetail.call_id;

			// UPDATE THE CALL DETAIL BY CALL DETAIL ID.
			// updateCallFirestore (updateID, column);

			// INSERT CALL DETAIL IN FIRESTORE.
			await insertCallRecordIntoFirestore (updateID);
			terminateCallConnection();
		}
		else
		{
			leaveCall (reject)
		}
	}
	
	const handleErrorOnCall = async (data) =>
	{
		console.log ("handleErrorOnCall", data)
		setcallRejected (data.reject)
		if (data.reject)
		{
			let updateID = data.call_id;
			
			
			// INSERT CALL DETAIL IN FIRESTORE.
			await insertCallRecordIntoFirestore (updateID);
			navigate("/callErrorHandler", { state: { message: data.reason } });
			terminateCallConnection();
		}
	}
	
	
	// FUNCTION TO HANDLE TO BOTH FUNCTIONALITY INCOMING-CALL AND OUTGOING-CALL.
	// UPDATE THE USER MEDIA STATUS ENABLE AND MUTE DETAIL.
	const handleUserMediaUpdate = ({type, currentMediaStatus}) =>
	{
		console.log("update user media calling>>>>>>>", {type, currentMediaStatus})
		if (currentMediaStatus !== null || currentMediaStatus.length !== 0)
		{
			switch (type)
			{
				case "video":
					setUserVdoStatus (currentMediaStatus);
					
					// IF VIDEO STATUS IS FALSE THEN STOP THE REMOTE VIDEO TRACK.
					if (!currentMediaStatus && userVideo.current)
					{
						userVideo.current.srcObject = null;
					}
					break
				case "mic":
					setUserMicStatus(currentMediaStatus)
					break
				default:
					setUserMicStatus(currentMediaStatus[0])
					setUserVdoStatus(currentMediaStatus[1])
					break
			}
		}
	}
	
	// this method is used to mute/unmute our stream
	const updateMic = () =>
	{
		setMyMicStatus((currentStatus) =>
		{
		  socket.emit("updateMyMedia",
		  {
		    type: "mic",
		    currentMediaStatus: !currentStatus,
		    from: remoteUserSocketId,
		  })
		  stream.getAudioTracks()[0].enabled = !currentStatus
		  localStream.current.getAudioTracks()[0].enabled = !currentStatus
		  return !currentStatus
		})
	}

	// HANDLE ANY CHANGE IN WEBRTC CONNECTION STATE. POSSIBLE STATES ARE:
	// CONNECTING | CONNECTED | DISCONNECTED | FAILED | FAILED | CLOSED
	useEffect(() =>
	{
	    if (webrtcState == "disconnected" || webrtcState == "closed")
		{
			if (isCallStatus === "OUT")
			{
				console.error ("Webrtc connection has " + webrtcState);
				leaveCall ("Webrtc connection has " + webrtcState);
			}
			else
			{
				hangupCall();
			}
	    }
		if (webrtcState === "failed")
		{
			createLog ("webrtc_debug", "webrtc connection failed", "next: hangup call", "error");
		}
	}, [webrtcState])
	
    // FUNCTION TO TOGGLE BETWEEN AUDIO AND VIDEO
    const toggleVideoCall = async () =>
    {
        try
        {
			createLog ("webrtc_debug", "starting video call");
            let formUser = me ? me : socket?.id;
			let isVideoOn;
			
			// IF CALL TYPE IS AUDIO THEN CONVERT IT INTO VIDEO.
	        if (callType === "audio")
	        {
		        createLog ("webrtc_debug", "start video stream");
				await startStream ("video");
		        sendOfferToRemoteUser (remoteUserSocketId, remoteUsername, me, location).then();
		        isVideoOn = true;
	        }
			else
	        {
				isVideoOn = false;
				stopVideoTrack();
	        }
	        setCallType (isVideoOn ? "video" : "audio");
	        setIsMyVideoOn (isVideoOn);

            // EMIT UPDATEMYMEDIA EVENT WITH VIDEO STATUS TO SERVER
            socket.emit("updateMyMedia",
            {
                type: "video",
                currentMediaStatus: isVideoOn, // IF THE VIDEO
                from: remoteUserSocketId,
            });
        }
        catch (e)
        {
			createLog ("webrtc_debug", "failed to start camera", "error", e);
        }
    };
	
	// FUNCTION TO STOP VIDEO TRACK
	const stopVideoTrack = () =>
	{
		// MAKE SURE STREAM IS AVAILABLE.
		if (localStream.current)
		{
			let peerConnection = getPeerConnection();
			localStream.current?.getVideoTracks()?.forEach ((track) =>
			{
				track.stop();
				localStream.current.removeTrack (track); // REMOVE FROM THE LOCAL STREAM
			})
			setStream (localStream.current);
			
			// IF PEER CONNECTION IS AVAILABLE THEN REMOVE THE VIDEO TRACK FROM PEER CONNECTION.
			if (peerConnection && peerConnection?.getSenders()?.length > 0)
			{
				// FIND AND REMOVE THE SENDER ASSOCIATED WITH THE VIDEO TRACK
				peerConnection.getSenders().forEach ((sender) =>
				{
					if (sender.track && sender.track.kind === 'video')
					{
						peerConnection.removeTrack (sender);
					}
				});
			}
		}
		myVideo.current.srcObject = null;
		
		// SET THE VIDEO CALL FLAG TO TRUE.
		isItVideoCall.current = false;
	}

	// CHECK THE MEDIA DEVICES AND SHOW SWITCH CAMERA OPTION IF MULTIPLE CAMERAS ARE AVAILABLE.
	const checkMediaDevices = async() =>
	{
		try
		{
			const devices = await navigator.mediaDevices.enumerateDevices();
			const videoInputDevices = devices.filter (device => device.kind === 'videoinput');

			// MULTIPLE CAMERAS ARE AVAILABLE, SHOW SWITCH CAMERA OPTION
			if (videoInputDevices.length > 1)
			{
				setSupportsMultipleCameras (true);
			}
			else
			{
				// ONLY ONE CAMERA AVAILABLE, HIDE SWITCH CAMERA OPTION
				setSupportsMultipleCameras (false);
			}
		} catch (err)
		{
			console.error ('Error enumerating devices:', err);
			setSupportsMultipleCameras (false);
		}
	}

	// INITIALIZE THE AUDIO STREAM. AND ADD AUDIO TRACK TO PEER.
	const startAudioOnlyCall = async () =>
    {
		// TRY TO GET THE AUDIO STREAM.
		try
		{
			await startStream ("audio");
			
			// SEND OFFER TO REMOTE USER. THE CALLER WILL TRIGGER THIS.
			sendOfferToRemoteUser (remoteUserSocketId, remoteUsername, me, location).then();
			console.log ("webrtc_debug: audio track added.")
		}
		catch (e)
		{
			console.error ("error in getting audio stream", e);
			let reason = callerCode.current + " video stream not available.";
			
			// CALL ENDED IF ERROR OCCURS.
			leaveCall (reason, true);
		}
    }
	
	const startPeerConnection = (remote_user_id) =>
	{
		let peerConnection = getPeerConnection(); // GET EXISTING PEER CONNECTION.
		
		// IF PEER CONNECTION IS NOT AVAILABLE THEN CREATE A NEW PEER CONNECTION.
		if (peerConnection)
		{
			console.log ("webrtc_debug: peer connection already exists. Returning existing instance");
			return peerConnection;
		}
		peerConnection = setPeerConnection (remote_user_id, remoteUsername, me, location)
		
		// PEER EVENTS
		peerConnection.oniceconnectionstatechange = handleIceConnectionStateChangeEvent;
		peerConnection.ontrack = (event) => handleOnTrackEvent (event, remote_user_id);
		peerConnection.onconnectionstatechange = (ev) => handleConnectionStateChangeEvent(ev, peerConnection);
		peerConnection.onicecandidateerror = (event) =>
		{
			console.error(`ICE Candidate Error: ${event.errorCode} - ${event.errorText}`);
		};
		return peerConnection;
	}
	
	const switchCam = async () =>
	{
		// THIS SHOULD BE A VIDEO CALL TO SWITCH THE CAMERA.
		if (callType === "audio")
		{
			createLog ("webrtc_debug","tried to switch camera on audio call", "error");
			return;
		}
		let isItFrontCam = !frontCam;
		
		// SET THE FACING MODE BASED ON frontCam STATE
		constraints.video.facingMode = isItFrontCam ? "user" : "environment";
		try
		{
			const currentStream = await navigator.mediaDevices.getUserMedia ({...constraints.video});
			let peerConnection = getPeerConnection();
			
			// GET THE VIDEO TRACK AND SET THE STREAM OBJECT.
			let videoTrack = currentStream.getVideoTracks ()[0];
			await peerConnection?.getSenders()?.filter ((sender) =>
				sender.track?.kind === "video")[0].replaceTrack (videoTrack);
		}
		catch (error)
		{
			createLog ("webrtc_debug", "error flipping camera", error);
		}
        setfrontCam (!frontCam);
    }

	// THE CALLER WILL BE TRIGGERED TO END THE CALL.
	const leaveCall = async (reason, error =false) =>
	{
		let call_id = callId || globals.call_id;
		createLog ("webrtc_debug", "Leave call was triggered with reason: ", reason, "call id is "+call_id);
		terminateCallConnection();
		
		// CREATE THE UPDATE PAYLOAD OBJECT.
		const payload =
		{
			"action": "endtime",
			"call_id": call_id,
		}
		httpRequest (`static/updatecalltime`, 'POST', payload).then();
		let endedPayload =
		{
			callendreason: (reason)? reason : "The caller has ended the call.",
			toHaveError: error,
			errorFrom: callerCode.current
		}

		// STATUS UPDATE CALL ENDED IN REALTIME DATABASE.
		updateCallStatus (call_id, "ENDED", endedPayload).then();

        socket.off ("offer-candidate", onIceCandidateOffer);

		// INSERT CALL DETAIL IN FIRESTORE.
		insertCallRecordIntoFirestore (call_id).then();
		
		// IF ERROR OCCURS THEN NAVIGATE TO ERROR PAGE. OTHERWISE, NAVIGATE TO THANKS-CALL OR DASHBOARD PAGE.
		if (error)
		{
			socket.emit ("errorOnCall", remoteUserSocketId, reason, call_id)
			console.log( "call emit ended with error.");
			
			// NAVIGATE TO ERROR PAGE.
			navigate ("/callErrorHandler", { state: { message: reason +'. Please try again later.' } });
		}
		else
		{
			socket.emit ("endCall", remoteUserSocketId)
			
			// NAVIGATE TO THANKS-CALL OR DASHBOARD PAGE.
			navigate (isCallStatus === "OUT" ? "/thanks-call" : "/dashboard", {state: {callId: call_id}});
		}
		console.log ("call ended.");
	}

	// THIS WILL BE TRIGGERED BY USER/CALLEE. END THE CALL FOR MOBILE USER AND INFORM CALLER ABOUT CALL END.
	const hangupCall = async () =>
	{
		console.log ("calling hangupCall , remoteUserSocketId: "+remoteUserSocketId)

		// SEND THE SERVER CALL ENDED EVENT IF SOCKET IS AVAILABLE
		socket.emit ("endCall", remoteUserSocketId);
		socket.off ("offer-candidate", onIceCandidateOffer);

		// CREATE THE UPDATE PAYLOAD OBJECT.
		const payload =
		{
			"action": "endtime",
			"call_id": globals.call_id,
		}
		// console.log ("Call answer payloadpayload:",payload);
		httpRequest (`static/updatecalltime`, 'POST', payload).then();
		let endedPayload =
		{
			callendreason: "The callee has ended the call."
		}

		// STATUS UPDATE CALL ENDED IN REALTIME DATABASE AND FIRESTORE DATABASE.
		await updateCallStatus (globals.call_id, "ENDED", endedPayload);
		updateCallStatusByUserId (globals.call_id, "ENDED", user.user_id).then();

		// INSERT CALL DETAIL IN FIRESTORE.
		await insertCallRecordIntoFirestore (globals.call_id);

		terminateCallConnection();
		navigate ("/dashboard");
	};

	// TERMINATE THE CALL CONNECTION.
	const terminateCallConnection = () =>
	{
		console.log ("terminate call connection");
		handleResetButton()
		if (localStream.current)
		{
			createLog ("webrtc_debug", "stopping local stream");
			localStream.current.getTracks().forEach ((track) =>
			{
				track.stop();
				createLog ("webrtc_debug", "stopping track", track.kind);
				localStream.current.removeTrack (track);
			});
			localStream.current = null;
			setStream (null);
		}
		if (myVideo.current)
		{
			myVideo.current.srcObject = null;
		}
		connectionRef && connectionRef?.current?.destroy();
		call_detail_id.current = null;
		globals.call_id = null;
		console.log("trying to close peer connections...");
		closeUserPeerConnections();
		setCallEnded (true);
		setCallId (null);

		// UNSUBSCRIBE THE LISTENER TO CALL SOCKET EVENTS.
		unsubscribeToAllCallSocketEvents();
		// disconnectedSocket();
	}

	// UNSUBSCRIBE THE LISTENER TO CALL SOCKET EVENTS.
	const unsubscribeToAllCallSocketEvents = () =>
	{
		// CHECK IF socket IS DEFINED BEFORE TRYING TO UNSUBSCRIBE
		if (socket && socket.connected)
		{
			// UNSUBSCRIBE FROM SOCKET EVENTS
			socket.off ("staff-unavailable", handleStaffUnavailable);
			socket.off ("staff-details", handleStaffDetail);
			socket.off ("offer-candidate", onIceCandidateOffer);
			socket.off ("offer", handleOffer)
			socket.off ("updateUserMedia", handleUserMediaUpdate)
			socket.off ("answer", handleAnswer)
			socket.off ("endCall", handleEndCall)
			socket.off ("errorOnCall", handleErrorOnCall)
			socket.off ("getCallIdFromServer", getCallIdFromServer);
			socket.off ("send_message", handleMessages)
			socket.off ("send_message_response", handleMessagesResponse)
		}
	}

	// DISCONNECTED SOCKET SERVER.
	const disconnectedSocket = ()=>
	{
		if (socket && socket.connected)
		{
			socket.disconnect();
			//socket = null;

			// NOW RECONNECT THE SOCKET.
			socket.connect();
		}
		else
		{
			console.log ("disconnected Socket () socket null or disconnected found ");
		}
	}

	// GET THE AUDIO OUTPUT DEVICES.
	async function getAudioOutputDevices()
	{
		try
		{
			const devices = await navigator.mediaDevices.enumerateDevices();
			const audioOutputs = devices.filter (device => device.kind === 'audiooutput');
			console.log ("audioOutputs", audioOutputs);
			return audioOutputs;
		}
		catch (error)
		{
			console.error('Error enumerating devices:', error);
			return [];
		}
	}

	// TOGGLE THE LOUDSPEAKER FOR AUDIO CALL
	const switchLoudSpeaker = async () =>
	{
		try
		{
			// GET THE AUDIO OUTPUT DEVICES
			const audioOutputDevices = await getAudioOutputDevices();

			// FIND THE LOUDSPEAKER AND PHONE SPEAKER THEN CHECK DEVICE AVAILABILITY
			const loudspeaker = audioOutputDevices.find (device => device.deviceId === 'default');
			const phoneSpeaker = audioOutputDevices.find (device => device.deviceId !== 'default');
			console.log ("loudspeaker", loudspeaker, "phoneSpeaker", phoneSpeaker);

			if (loudspeaker || phoneSpeaker)
			{
				// CHECK USER VIDEO ELEMENT IS AVAILABLE.
				if (userVideo.current)
				{
					const audioElement = userVideo.current;

					// CHECK IF BROWSER SUPPORTS OUTPUT DEVICE SELECTION
					if (!audioElement.setSinkId)
					{
						console.error ('Browser does not support output device selection.');
						return;
					}

					// SELECT THE DEVICE BASED ON THE CURRENT SPEAKER STATE
					const selectedDevice = phoneSpeaker === 'external' ? loudspeaker.deviceId : phoneSpeaker.deviceId;

					try
					{
						await audioElement.setSinkId (selectedDevice);
						console.log(`Switched to ${(phoneSpeaker === 'external') ? 'loudspeaker' : 'phone speaker'}`);
					}
					catch (error)
					{
						console.error('Error setting sink ID:', error);
					}
				}
				else
				{
					console.error ('userVideo not found.');
				}
			}
			else
			{
				console.error ('Suitable audio output devices not found.');
			}

			// TOGGLE THE SPEAKER STATE
			setPhoneSpeaker (prev => (prev === 'external' ? 'phone' : 'external'));
		} catch (error) {
			console.error('Error switching loudspeaker:', error);
		}
	};

	return (
    <VideoContext.Provider
        value={{
	        switchCam,
	        isSafariBrowser,
	        staffTimeOut,
	        callRejected,
	        sec,
	        call,
	        callAccepted,
	        myVideo,
	        userVideo,
	        userAudio,
	        stream,
	        remoteUsername,
	        callEnded,
	        me,
	        leaveCall,
	        setRemoteUserSocketId,
            username,
	        isMyVideoOn,
	        setIsMyVideoOn,
	        userVdoStatus,
	        setUserVdoStatus,
	        myMicStatus,
	        userMicStatus,
	        updateMic,
	        remoteUserSocketId,
            staffUnavailable,
	        message,
	        callStatus,
	        hangupCall,
	        callType,
	        isCallStatus,
	        toggleVideoCall,
	        switchLoudSpeaker,
	        phoneSpeaker,
	        isItVideoCall,
	        supportsMultipleCameras,
	        isDirectCallEnabled,
	        isCallConnected,
	        isHideOptionButtons,
		}}
    >
		<>
	      {children}
		</>
    </VideoContext.Provider>
  )
}

export default VideoState
