/*
 AUTHOR: QASIM ZUBAIR
 SUMMARY: GET CALLING SERVIES.
 */

import {globals} from "../utils/globals"
import {getLoggedInUser} from "./login.service"
import {socket} from "./helper.service"


// MAKE SURE THE SOCKET CONNECTION IS READY. IF NOT THEN ESTABLISH IT.
export const readyTheSocketConnection = async () =>
{
	return new Promise (resolve =>
	{
		if (globals.socket && globals.socket?.connected && typeof (globals.socket?.id) !== "undefined")
		{
			console.log ("socket already connected", new Date().getTime ())
			socketConnectionEstablished();
			resolve (true);
		}
		else
		{
			console.log ("Socket was not connected.Now trying to connecting.");
			globals.socket = socket;

			socket.on ("connect", onSocketConnected);
			globals.socket = socket;
			socketConnectionEstablished();
			resolve (true);
		}
	});
}
export const socketConnectionEstablished = () =>
{
	globals.localUser.socket_id = globals.socket.id; // UPDATE SOCKET ID OF LOCAL USER
	console.log ("socket connection is established", globals.localUser.socket_id);
}

//
export const onSocketConnected = async (data: any) =>
{
	if (socket.connected)
	{

		console.log ('Is socket connected:', socket.connected);
		console.log ("socket_connected", new Date().getTime (), "me ID =====>>>>>>", socket.id)

		let user_email = globals.email;
		if (!user_email)
		{
			// GET CURRENT USER'S PROFILE DATA.
			let user = await getLoggedInUser();
			user_email = user?.email
		}
		console.log ("socket_connected email value " + user_email);

		// THIS EVENT TO JOIN THE STAFF EMAIL IN SOCKET SERVER.
		socket.emit ("staff-join", user_email);

	}
}

export const onSocketDisconnect = async (data: any) =>
{
	console.log ("disconnected the Socket====>", data);
}

export const onSocketConnectionFailure = async (data: any) =>
{
	console.log ("onSocketConnectionFailure the Socket====>", data);
}

// THIS WILL REJECT THE CALL IF IT IS CONNECTED.
export const rejectCall = (data: any | undefined) =>
{
	console.log ("SOCKET_ON_ENDCALL_EVENT => obj data : ", data);
	globals.peerConnection = null;
	globals.localUser = {};
	globals.remoteUsers = {};
}

// IF THERE IS AN ONGOING AND CONNECTED CALL.
export const hasOnGoingCall = () =>
{
	return (globals.activeCallId > 0 && globals.activeCallStatus == "CONNECTED");
}
